import React from "react"



function Gallery() {
  return (
    <>
      <div className="box">
        SoonTM
      </div>
    </>
          

  )
}

export default Gallery